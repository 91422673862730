// const humanizeDuration = require("humanize-duration");

import './augmented.css';

//https://lea.verou.me/blog/2020/07/import-non-esm-libraries-in-es-modules-with-client-side-vanilla-js/
import './particles.min.js';

import { DateTime } from "./luxon.min.js";



particlesJS('particles-js',

    {
      "particles": {
        "number": {
          "value": 50,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": "#78B8D0"
        },
        "shape": {
          "type": "circle",
          "stroke": {
            "width": 0,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          },
          "image": {
            "src": "img/github.svg",
            "width": 100,
            "height": 100
          }
        },
        "opacity": {
          "value": 0.5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 5,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 40,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 250,
          "color": "#ffffff",
          "opacity": 0.4,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 6,
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": false,
            "mode": "repulse"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 40,
            "duration": 2,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 20
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true,
      "config_demo": {
        "hide_card": false,
        "background_color": "#b61924",
        "background_image": "",
        "background_position": "50% 50%",
        "background_repeat": "no-repeat",
        "background_size": "cover"
      }
    }

  );


  //Countdown
  //https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/

  //var DateTime = luxon.DateTime;
  let instant = DateTime.local();


  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(instant);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  }

  function initializeClock(id) {
    const clock = document.getElementById(id);
    const daysSpan = clock.querySelector('.days');
    const hoursSpan = clock.querySelector('.hours');
    const minutesSpan = clock.querySelector('.minutes');
    const secondsSpan = clock.querySelector('.seconds');
    let timeinterval = 0;

    function updateClock() {
      var endtime = calcDeadline();
      const t = getTimeRemaining(endtime);

      daysSpan.innerHTML = t.days;
        hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
        minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
        secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

      endtime = endtime.set({minute: 0});
      let localtime =  endtime.toLocal();
      document.getElementById('localtime').innerHTML = 'Official Time: ' + endtime.toLocaleString(DateTime.DATETIME_HUGE) +
      '<br/>Your Local Time: ' + localtime.toLocaleString(DateTime.DATETIME_HUGE);

      instant = DateTime.local();
    }

    updateClock();
    timeinterval = setInterval(updateClock, 1000);
  }

  function nextDay(x) {
    var local = DateTime.local();
    var now = local.setZone("Europe/Berlin").set({ weekday: x,hour: 21, minute: 15, second: 0 });
    if (local > now)
    {
      now = now.plus({days:7});
    }
    return now;
  }

  function calcDeadline() {
    let thursday = nextDay(4);
    let sunday = nextDay(7);

    // console.log('thursday',thursday.toLocaleString(DateTime.DATETIME_HUGE));
    // console.log('sunday',sunday.toLocaleString(DateTime.DATETIME_HUGE));

    let until_thursday = Math.max(thursday - instant,0);
    let until_sunday = Math.max(sunday - instant,0);


    let deadline;
    if (until_thursday > until_sunday) {
      if (until_sunday == 0)
      {
        deadline = thursday;
      }else{
        deadline = sunday;
      }
    } else {
      if (until_thursday == 0)
      {
        deadline = sunday;
      }else{
        deadline = thursday;
      }
    }

    return deadline;
  }

 
  initializeClock('clockdiv');